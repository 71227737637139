import React, { useState, useEffect } from "react";

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@runly/gatsby-theme";

import { groupBy, startCase } from "lodash";

import {
	VerifiedUser as LatestVersionIcon,
	Warning as LegacyVersionIcon
} from "@material-ui/icons";

const VersionGrid = ({
	platform,
	version,
	isLatest,
	files: _files,
	dockerNodeRepo
}) => {
	const files = _files.filter(f => f.platform.toLowerCase() === platform);
	const classes = useStyles();
	const [ua, setUa] = useState("");
	useEffect(() => {
		const _ua = window?.navigator?.userAgent;

		if (_ua) {
			setUa(_ua);
		}
	}, []);

	if (!files) return null;

	const dockerCommand = `docker pull runly/runly:${
		isLatest ? "latest" : version
	}`;
	const types = groupBy(files, "type");

	const howToRunLink = (
		<Link
			style={{ marginLeft: "auto" }}
			to={`/docs/platform/node/${platform}/`}
			title={`Run the node on ${startCase(platform)}`}
		>
			How to run
		</Link>
	);

	return (
		<Box display={isLatest ? "block" : ["block", "block", "flex"]}>
			<Box display="flex" alignItems="center" mr={2}>
				<Box clone display="flex" alignItems="center">
					<Typography
						variant={isLatest ? "h6" : "body2"}
						component="h2"
						color={isLatest ? "textPrimary" : "textSecondary"}
						gutterBottom
					>
						{isLatest ? <LatestVersionIcon /> : <LegacyVersionIcon />} v
						{version} {isLatest ? "(Latest)" : "(Legacy)"}
					</Typography>
				</Box>
				{isLatest ? howToRunLink : null}
			</Box>
			<Box py={isLatest ? 2 : 0}>
				{dockerNodeRepo && (!platform || platform === "docker") ? (
					<>
						<Box py={0.5}>
							<Link
								href={`${dockerNodeRepo}/tags?name=${
									isLatest ? "latest" : version
								}`}
							>
								repo
							</Link>
						</Box>
						<Box>
							<Box
								className={classes.dockerCommand}
								py={1}
								px={2}
								borderRadius={8}
								component="pre"
							>
								<code>{dockerCommand}</code>
							</Box>
						</Box>
					</>
				) : (
					<Box display="flex">
						{Object.keys(types).map(type => {
							const files = types[type];

							return (
								<Box key={type} display="flex" flexWrap="wrap" py={0.5}>
									<div>{type}:</div>

									{files.map(file => (
										<Link
											href={file.url}
											key={file.name}
											className={classes.link}
											style={{
												fontWeight: ua.includes(file.architecture)
													? "bold"
													: "normal"
											}}
										>
											{file.architecture}
										</Link>
									))}
								</Box>
							);
						})}
					</Box>
				)}
			</Box>
		</Box>
	);
};

const useStyles = makeStyles(theme => ({
	platformLogo: { margin: theme.spacing(0, 1, 0, 0) },
	link: {
		margin: theme.spacing(0, 1)
	},
	dockerCommand: {
		backgroundColor: "#1e1e1e",
		color: "#d4d4d4",
		overflowX: "auto"
	}
}));

export default VersionGrid;
