import React from "react";
import { graphql } from "gatsby";

import { Container, Box } from "@material-ui/core";

import Layout from "../layout";
import Seo from "../seo";

import DownloadGrid from "../downloads";

import { Mdx } from "@runly/gatsby-theme";
import { startCase } from "lodash";

const DownloadPage = ({
	location,
	data: {
		site: {
			siteMetadata: { dockerNodeRepo }
		},
		allNodeDownload: { nodes },
		file: {
			childMdx: { body: introContent }
		}
	},
	pageContext: { platform }
}) => {
	return (
		<Layout location={location}>
			<Seo
				title={platform ? `${startCase(platform)} Downloads` : "Downloads"}
				description="Run a runly node in production."
			/>

			<Container>
				<Box mb={6} component="article">
					<Mdx>{introContent}</Mdx>
				</Box>

				<DownloadGrid
					platform={platform}
					nodes={nodes}
					dockerNodeRepo={dockerNodeRepo}
				/>
			</Container>
		</Layout>
	);
};

export default DownloadPage;

export const downloadQuery = graphql`
	query DownloadQuery {
		site {
			siteMetadata {
				dockerNodeRepo
			}
		}
		file(name: { eq: "downloads" }, sourceInstanceName: { eq: "snippet" }) {
			childMdx {
				body
			}
		}
		allNodeDownload {
			nodes {
				version
				files {
					architecture
					extension
					name
					platform
					type
					url
				}
			}
		}
	}
`;
