import React from "react";

import { Card, Divider, Box, SvgIcon, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { Link } from "@runly/gatsby-theme";
import VersionGrid from "./version-grid";

import { platformLogos } from "./platform-logos";

const platforms = ["Windows", "Mac", "Linux", "Docker"];

const Downloads = ({ platform = "docker", nodes, dockerNodeRepo }) => {
	const [latest, ...legacy] = nodes;

	const classes = useStyles();

	return (
		<>
			<Box display="flex" flexDirection={["column", "row"]}>
				{platforms.map(p => {
					const isActive = p.toLowerCase() === platform.toLowerCase();

					const TabComponent = isActive ? Typography : Link;

					return (
						<TabComponent
							color={isActive ? undefined : "inherit"}
							textDecoration={isActive ? undefined : "none"}
							style={{ display: "block", flexGrow: 1 }}
							key={p}
							component={isActive ? "div" : Link}
							to={isActive ? null : `/downloads/${p.toLowerCase()}/`}
							className={classes.tab}
						>
							<Box
								clone
								py={2}
								px={3}
								display="flex"
								alignItems="center"
								justifyContent="center"
								borderColor={isActive ? "primary.main" : "background.default"}
								className={classes.tabInner}
							>
								<Typography variant="h6" component="div">
									<Box clone pr={1}>
										<SvgIcon style={{ fontSize: "1.5em" }}>
											<path d={platformLogos[p]} />
										</SvgIcon>
									</Box>
									{p}
								</Typography>
							</Box>
						</TabComponent>
					);
				})}
			</Box>
			<Box clone mb={2}>
				<Card elevation={4}>
					<Box px={2} pt={2} pb={4}>
						<VersionGrid
							platform={platform}
							version={latest.version}
							isLatest
							files={latest.files}
							dockerNodeRepo={dockerNodeRepo}
						/>
					</Box>
				</Card>
			</Box>
			<Card>
				{legacy.map(n => (
					<Box key={n.version}>
						<Box px={2} py={1}>
							<VersionGrid
								platform={platform}
								version={n.version}
								files={n.files}
								dockerNodeRepo={dockerNodeRepo}
							/>
						</Box>
						<Divider />
					</Box>
				))}
			</Card>
		</>
	);
};

const useStyles = makeStyles(theme => {
	return {
		tab: {
			"&, &:hover, &:focus": { textDecoration: "none" }
		},
		tabInner: {
			borderLeftWidth: 2,
			borderLeftStyle: "solid",
			[theme.breakpoints.up("sm")]: {
				borderLeftWidth: 0,
				borderTopWidth: 2,
				borderTopStyle: "solid"
			}
		}
	};
});

export default Downloads;
